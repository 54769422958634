import { FadeIn } from "~/components/ui/fade-in";
import { ReactNode } from "react";

export const FadeNavbarItem = ({
  children,
  className,
  delay,
  onAnimationComplete,
}: {
  children: ReactNode;
  className?: string;
  delay: number;
  onAnimationComplete?: () => void;
}) => {
  return (
    <FadeIn
      direction="down"
      className={className}
      framerProps={{
        show: {
          transition: { delay },
        },
      }}
      onAnimationComplete={onAnimationComplete}
    >
      {children}
    </FadeIn>
  );
};
