export const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer
      className={"h-breadcrumb-height flex w-full justify-center bg-background"}
    >
      <div
        className={
          "container mx-2 flex h-full items-center justify-between border-t border-border px-4 text-xs font-thin"
        }
      >
        <div>
          <span className={"text-foreground/60"}>
            &copy; {year} Vesas s.r.o.
          </span>
        </div>
        <span className={"text-foreground/50"}>
          Web na míru od{" "}
          <a
            className={"text-foreground/60 hover:underline"}
            aria-label={"Dominik Jašek"}
            href="https://www.dominikjasek.cz"
          >
            Dominik Jašek
          </a>
        </span>
      </div>
    </footer>
  );
};
