export const Logo = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0.42 0.87 162.81 35.27"
      {...props}
    >
      <path
        d="M139.262 34.4175C141.981 35.5377 144.893 36.1212 147.835 36.134C149.98 36.1251 152.112 35.7967 154.16 35.1583C156.361 34.3906 158.389 33.1929 160.121 31.6367C160.786 30.9513 161.37 30.1918 161.864 29.3749C163.122 27.2423 163.528 24.7195 163.004 22.3034C162.94 22.0136 162.862 21.7273 162.771 21.4444C161.594 18.0651 158.902 15.4226 155.49 14.2945C153.666 13.6825 151.814 13.1557 149.94 12.7164C149.615 12.6014 149.343 12.3745 149.173 12.0769C149.003 11.7799 148.946 11.4313 149.011 11.0957C149.078 10.7599 149.263 10.459 149.533 10.2478C149.803 10.0359 150.142 9.92772 150.485 9.94226C151.396 9.94003 152.304 10.0543 153.185 10.2818C154.413 10.5688 155.632 10.9005 156.836 11.2762L157.684 11.5317L159.317 12.0591L162.258 4.18838L160.666 3.33602C157.988 2.07569 155.112 1.28326 152.165 0.993488C149.806 0.685917 147.408 0.936824 145.165 1.72708C143.103 2.34856 141.205 3.41628 139.607 4.85427C138.602 5.82314 137.774 6.95857 137.159 8.21004C136.789 8.89934 136.492 9.62618 136.275 10.3783C135.649 12.4641 135.725 14.6969 136.493 16.7351C136.787 17.504 137.192 18.2258 137.695 18.8797C138.565 20.0438 139.688 20.9976 140.981 21.6696C142.787 22.6889 144.768 23.3665 146.824 23.6686L148.02 23.815C148.718 23.9411 149.255 24.5009 149.345 25.2009C149.437 25.9003 149.064 26.5789 148.42 26.8782C148.018 27.0239 147.593 27.0995 147.164 27.1001C146.672 27.1202 146.18 27.0828 145.699 26.989C144.704 26.8103 143.728 26.5419 142.783 26.1871C140.854 25.3835 139.055 24.3008 137.444 22.9742L134.609 32.0811L139.262 34.4175Z"
        fill="#2056AE"
      />
      <path
        d="M0.420898 1.82303L13.7442 35.2195L25.6286 35.2979L38.0525 1.72208L24.0499 1.70192L20.036 17.0944L14.8725 1.82967L0.420898 1.82303Z"
        fill="#2056AE"
      />
      <path
        d="M64.7657 35.4779L64.7559 26.0101L53.8153 26.0368L53.7555 22.9132L63.5398 22.9088V13.9969L53.819 14.003L53.8244 10.9016L64.3728 10.9028L64.3885 1.52209L41.0392 1.61562L40.9561 35.4423L64.7657 35.4779Z"
        fill="#2056AE"
      />
      <path
        d="M72.702 34.4179C75.4211 35.5381 78.3335 36.1212 81.2761 36.134C83.4216 36.1248 85.5532 35.7965 87.5998 35.1583C89.8028 34.3906 91.8293 33.1933 93.5603 31.6372C94.2258 30.9509 94.8113 30.1925 95.3044 29.3754C96.561 27.2429 96.968 24.7191 96.4446 22.3032C96.3796 22.0136 96.302 21.7273 96.2117 21.444C95.0333 18.0655 92.3426 15.4231 88.9304 14.2944C87.1064 13.6825 85.2541 13.1562 83.3798 12.7167C83.0556 12.6013 82.7842 12.375 82.6139 12.0773C82.4436 11.7797 82.3871 11.432 82.4529 11.096C82.5188 10.7606 82.7035 10.4593 82.9741 10.2478C83.2439 10.0357 83.5823 9.92826 83.9259 9.94262C84.8365 9.94057 85.7436 10.0548 86.6251 10.2825C87.8541 10.5692 89.0727 10.9003 90.2767 11.2758L91.1248 11.5313L92.7574 12.0594L95.6988 4.18874L94.1068 3.33657C91.4289 2.07557 88.5529 1.28356 85.6046 0.993971C83.246 0.685555 80.848 0.937368 78.6049 1.72763C76.5432 2.34838 74.6451 3.41676 73.048 4.85415C72.0423 5.82315 71.2137 6.95911 70.5988 8.21004C70.2285 8.8997 69.9321 9.62666 69.7153 10.3783C69.0892 12.4648 69.1655 14.6967 69.933 16.7356C70.2282 17.5043 70.6331 18.2262 71.135 18.8796C72.0056 20.0441 73.1286 20.9979 74.4214 21.6702C76.2279 22.6889 78.209 23.3663 80.2632 23.669L81.4604 23.815C82.1581 23.9407 82.6946 24.5014 82.7862 25.2009C82.8781 25.9006 82.5038 26.5787 81.8618 26.8779C81.4589 27.0244 81.0332 27.0995 80.6043 27.1004C80.1135 27.1208 79.6214 27.0831 79.1392 26.9897C78.1452 26.8105 77.1692 26.5416 76.2243 26.1869C74.2945 25.3842 72.4953 24.3014 70.8849 22.9741L68.0498 32.0809L72.702 34.4179Z"
        fill="#2056AE"
      />
      <path
        d="M119.963 35.5219L133.229 35.6295L122.321 1.45067L108.073 1.37041L96.624 35.6104L109.959 35.6295L110.961 30.9545L118.876 30.8709L119.963 35.5219ZM113.165 23.1193L115.147 16.9109L117.038 23.1153L113.165 23.1193Z"
        fill="#2056AE"
      />
    </svg>
  );
};
