import { components } from "~/openapi/schema";
import React, { useState } from "react";
import { cn } from "~/lib/utils";
import { NavLink } from "@remix-run/react";
import { Button } from "~/components/ui/button";
import { FadeNavbarItem } from "~/components/navbar/fade-navbar-item";
import { ChevronSharpRight } from "~/components/ui/icons/ChevronSharpRight";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/components/ui/tooltip";

type NavbarDropdownNavItemProps = {
  dropdown: components["schemas"]["UiDropdownComponent"];
  delay: number;
  onNavigate: () => void;
};

export const NavbarDropdownNavItem: React.FC<NavbarDropdownNavItemProps> = ({
  dropdown,
  onNavigate,
  delay,
}) => {
  const [isAnimationComplete, setIsAnimationComplete] = useState(false);
  const [isOpenOnMobile, setIsOpenOnMobile] = useState(false);

  return (
    <FadeNavbarItem
      className={cn("flex w-full items-center")}
      delay={delay}
      onAnimationComplete={() => setIsAnimationComplete(true)}
    >
      <div className={"w-full md:hidden"}>
        <div className={"flex items-center justify-between"}>
          <Button
            asChild
            variant={"link"}
            size={"default"}
            className={cn(
              "flex w-3/4 justify-start text-lg font-semibold uppercase tracking-wide",
            )}
          >
            <NavLink to={dropdown.rootButton?.link ?? ""} onClick={onNavigate}>
              {dropdown.rootButton?.text}
            </NavLink>
          </Button>
          <ChevronSharpRight
            strokeWidth={3.5}
            className={cn("size-4 w-1/4 rotate-90 transition-all", {
              "-rotate-90": isOpenOnMobile,
            })}
            onClick={() => {
              setIsOpenOnMobile(!isOpenOnMobile);
            }}
          />
        </div>
        {isOpenOnMobile && (
          <ul className="flex flex-col divide-y divide-border/20">
            {dropdown.dropdownButtons?.map((item) => (
              <li key={item.text} className={"ml-4 py-2"}>
                <Button
                  asChild
                  variant={item.variant}
                  size={item.size}
                  className={cn("w-full justify-start text-lg uppercase")}
                >
                  <NavLink to={item.link ?? ""} onClick={onNavigate}>
                    {item.text ?? "Unknown link"}
                  </NavLink>
                </Button>
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className={"hidden md:block"}>
        <TooltipProvider skipDelayDuration={0} delayDuration={0}>
          <Tooltip delayDuration={0}>
            <TooltipTrigger
              className={
                "px-10 outline-none focus-visible:outline-none md:px-0"
              }
            >
              <Button
                asChild
                variant={"link"}
                size={"default"}
                className={cn(
                  "flex w-full gap-2 text-lg font-semibold uppercase tracking-wide",
                )}
              >
                <NavLink
                  to={dropdown.rootButton?.link ?? ""}
                  onClick={onNavigate}
                >
                  <span>{dropdown.rootButton?.text}</span>
                  <ChevronSharpRight
                    strokeWidth={3.5}
                    className={cn("rotate-90 transition-all", {
                      "-rotate-90": isOpenOnMobile,
                    })}
                  />
                </NavLink>
              </Button>
            </TooltipTrigger>

            {isAnimationComplete && (
              <TooltipContent
                className={cn(
                  "overscroll-y-hidden border-none bg-foreground p-1 text-background outline-none",
                )}
              >
                <ul className="flex flex-col divide-y divide-border/20">
                  {dropdown.dropdownButtons?.map((item) => (
                    <li key={item.text} className={"py-2"}>
                      <Button
                        asChild
                        variant={item.variant}
                        size={item.size}
                        className={cn("w-full justify-start text-lg uppercase")}
                      >
                        <NavLink to={item.link ?? ""} onClick={onNavigate}>
                          {item.text ?? "Unknown link"}
                        </NavLink>
                      </Button>
                    </li>
                  ))}
                </ul>
              </TooltipContent>
            )}
          </Tooltip>
        </TooltipProvider>
      </div>
    </FadeNavbarItem>
  );
};
