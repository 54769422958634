import { captureRemixErrorBoundaryError } from "@sentry/remix";
import {
  isRouteErrorResponse,
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useMatches,
  useRouteError,
} from "@remix-run/react";
import { json, LinksFunction } from "@remix-run/node";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "~/components/ui/breadcrumb";
import { Home } from "lucide-react";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
import "react-photo-view/dist/react-photo-view.css";

import tailwindCss from "./tailwind.css?url";
import ckeditorCss from "./ckeditor.css?url";
import { ENV } from "~/env";
import NotFoundPage from "~/components/pages/errors/NotFoundPage";
import ErrorPage from "~/components/pages/errors/ErrorPage";
import StrapiErrorPage from "~/components/pages/errors/StrapiErrorPage";
import { Navbar } from "~/components/navbar/navbar";
import { strapiRepository } from "~/openapi/strapiRepository";
import { TailwindIndicator } from "~/lib/tailwind-indicator";
import { RemixHandle } from "~/lib/remix";
import { Fragment } from "react";
import { cn } from "~/lib/utils";
import { Footer } from "~/components/footer/footer";
import { footerHeight, navbarHeight } from "../tailwind.config";
import { NotFoundException } from "~/lib/errors/NotFoundException";

export const loader = async () => {
  const { getNavbarItems } = strapiRepository;
  const [navbarResponse] = await Promise.all([getNavbarItems()]);

  if (navbarResponse.error) {
    throw navbarResponse.error;
  }

  return json({
    navbarData: navbarResponse.data,
    ENV,
  });
};

export const links: LinksFunction = () => [
  {
    rel: "apple-touch-icon",
    sizes: "180x180",
    href: "/apple-touch-icon.png",
  },
  {
    rel: "icon",
    type: "image/png",
    sizes: "32x32",
    href: "/favicon-32x32.png",
  },
  {
    rel: "icon",
    type: "image/png",
    sizes: "16x16",
    href: "/favicon-16x16.png",
  },
  {
    rel: "icon",
    type: "image/x-icon",
    href: "/favicon.ico",
  },
  {
    rel: "mask-icon",
    href: "/favicon.ico",
    color: "#2056AE",
  },
  { rel: "stylesheet", href: tailwindCss, type: "text/css" },
  {
    rel: "stylesheet",
    href: "https://cdn.ckeditor.com/ckeditor5/43.3.1/ckeditor5.css",
  },
  { rel: "stylesheet", href: ckeditorCss },
  { rel: "preconnect", href: "https://fonts.googleapis.com" },
  {
    rel: "preconnect",
    href: "https://fonts.gstatic.com",
    crossOrigin: "anonymous",
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Gabarito:wght@400..900&display=swap",
  },
];

export function Layout({ children }: { children: React.ReactNode }) {
  const title = "VESAS | Stavební firma z Třebíče";
  const description =
    "Jsme rodinná odborná stavební firma s více než 30letou tradicí. Zaměřujeme se na výstavbu a rekonstrukci rodinných domů a projekci a instalaci TZB.";
  const url = "https://vesas.dominikjasek.cz";

  return (
    <html lang="cs">
      <head>
        <meta charSet="utf-8" />
        <meta name="apple-mobile-web-app-title" content="VESAS" />
        <meta httpEquiv="Content-Language" content="cs" />
        <meta property={"og:type"} content={"website"} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, minimum-scale=1"
        />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:image" content={`${url}/og-image.png`} />
        <Meta />
        <Links />
        <script
          defer
          src="https://umami.dominikjasek.cz/script.js"
          data-website-id="e2952bb0-b305-4e10-94d3-bcdbc59f8503"
        ></script>
      </head>
      <body className={"font-gabarito"}>
        <div className={"mx-auto"}>{children}</div>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  console.log("Error happened", error);

  if (error instanceof NotFoundException) {
    return <NotFoundPage />;
  }

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <NotFoundPage />;
    }
  }

  if (error instanceof Error) {
    if (error.message.includes("fetch failed")) {
      return <StrapiErrorPage />;
    }
    return <ErrorPage errorMessage={error.message} />;
  }

  captureRemixErrorBoundaryError(error);
  return <ErrorPage />;
};

export default function App() {
  const { navbarData, ENV } = useLoaderData<typeof loader>();
  const { pathname } = useLocation();
  const matches = useMatches();

  const matchesWithHandle = matches.filter(
    (match) => match.handle && (match.handle as RemixHandle).breadcrumbTitle,
  );

  const isHomepage = pathname === "/";

  return (
    <>
      <script
        dangerouslySetInnerHTML={{
          __html: `window.ENV = ${JSON.stringify(ENV)}`,
        }}
      />
      {navbarData?.data && <Navbar navbarItems={navbarData.data} />}
      <div
        className={cn("flex flex-col justify-start pb-8", {
          "container mx-auto px-4": !isHomepage,
        })}
        style={{
          minHeight: `calc(100vh - ${navbarHeight}px - ${footerHeight}px)`,
        }}
      >
        {!isHomepage && (
          <Breadcrumb className={"h-breadcrumb-height"}>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbLink href="/">
                  <Home size={12} />
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              {matchesWithHandle.map((match, index) => (
                <Fragment key={match.id}>
                  <BreadcrumbItem>
                    <BreadcrumbLink asChild>
                      <Link to={match.pathname}>
                        {(match.handle as RemixHandle).breadcrumbTitle(match)}
                      </Link>
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                  {index < matchesWithHandle.length - 1 && (
                    <BreadcrumbSeparator />
                  )}
                </Fragment>
              ))}
            </BreadcrumbList>
          </Breadcrumb>
        )}
        <Outlet />
      </div>
      <Footer />
      <TailwindIndicator />
    </>
  );
}
