import { components } from "~/openapi/schema";
import { Button } from "~/components/ui/button";
import { Link } from "@remix-run/react";
import { cn } from "~/lib/utils";
import { useState } from "react";
import { MenuIcon } from "~/components/icons/menuIcon";
import { NavbarDropdownNavItem } from "~/components/navbar/dropdown-item";
import { FadeNavbarItem } from "~/components/navbar/fade-navbar-item";
import { Logo } from "~/components/ui/logo/logo";

export const Navbar = ({
  navbarItems,
}: {
  navbarItems: components["schemas"]["Navbar"];
}) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const closeMobileMenu = () => setIsMobileMenuOpen(false);

  return (
    <div
      className={cn(
        "sticky top-0 z-50 h-navbar-height w-full border-b-[1px] border-border/10 bg-background/50 backdrop-blur-md transition-all duration-500",
      )}
    >
      <nav
        className={cn(
          "container mx-auto flex h-full w-full flex-wrap items-center justify-between px-4 py-2 text-foreground md:py-4",
          {
            "text-background": isMobileMenuOpen,
          },
        )}
      >
        <Link to={"/"} aria-label={"Vesas"}>
          <Logo className={"w-28 md:w-32 lg:w-40"} />
        </Link>

        <MenuIcon
          isOpen={isMobileMenuOpen}
          className={"relative z-20 cursor-pointer md:hidden"}
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        />

        <div
          className={cn(
            "w-full md:flex md:w-auto md:items-center",
            {
              hidden: !isMobileMenuOpen,
            },
            {
              "no-doc-scroll absolute left-0 top-0 h-svh w-svw bg-foreground/95 pt-navbar-height":
                isMobileMenuOpen,
            },
          )}
        >
          <ul
            className={
              "flex w-full flex-col items-start divide-y divide-border/20 md:flex-row md:gap-4 md:divide-none"
            }
          >
            {navbarItems.nav_items?.map((item, index) => {
              switch (item.__component) {
                case "ui.button":
                  return (
                    <li
                      key={item.text}
                      className={"flex w-full items-center py-2 md:py-0"}
                    >
                      <Button
                        asChild
                        variant={item.variant}
                        size={item.size}
                        className={cn(
                          "w-full justify-start text-lg font-semibold uppercase tracking-wide",
                        )}
                        onClick={closeMobileMenu}
                      >
                        <Link to={item.link ?? ""}>
                          <FadeNavbarItem delay={(index + 1) * 0.1}>
                            {item.text}
                          </FadeNavbarItem>
                        </Link>
                      </Button>
                    </li>
                  );
                case "ui.dropdown":
                  return (
                    <li key={item.id} className={"w-full py-2 md:py-0"}>
                      <NavbarDropdownNavItem
                        delay={(index + 1) * 0.1}
                        dropdown={item}
                        onNavigate={closeMobileMenu}
                      />
                    </li>
                  );
              }
            })}
          </ul>
        </div>
      </nav>
    </div>
  );
};
